<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-25 10:11:25
 * @LastEditors: pj
 * @LastEditTime: 2023-04-25 16:15:38
-->
<template>
  <div class="order-detail">
    <gl-title title="退货管理" backIcon></gl-title>
    <gl-card>
      <el-tabs v-model="activeName">
        <el-tab-pane label="订单信息" name="orderInfo">
          <orderInfo :info="orderInfo" @confirm="handleConfirm" />
        </el-tab-pane>
      </el-tabs>
    </gl-card>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { getOrderAfterSales } from '@/api/order'
import orderInfo from './refund-orderInfo.vue'
import cargoInfo from './cargoInfo.vue'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { orderInfo, cargoInfo },
  props: {},
  data() {
    // 这里存放数据
    return {
      activeName: 'orderInfo',
      orderId: '',
      orderInfo: {}, //订单信息
      cargoInfo: {}, //物流信息
    }
  },
  // 计算属性类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getOrderDetail() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const res = await getOrderAfterSales(this.orderId)
      this.orderInfo = {
        ...res.data.orderDetailed,
        afterSalesList: res.data.afterSalesList,
      }
      loading.close()
    },
    handleConfirm() {
      this.getOrderDetail()
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getOrderDetail()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.order-detail {
  .el-tabs__nav-wrap {
    padding-left: 30px;
  }
}
</style>
