/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-06 16:19:34
 * @LastEditors: pj
 * @LastEditTime: 2023-04-28 17:48:53
 */
import request from '@/plugins/request'

// 获得批发订单列表
export function orderGoodsList (data = {}) {
  return request({
    url: '/api/order/parent/getBatchOrderList',
    method: 'POST',
    data: data,
  })
}
// 团购订单列表
export function getGroupBuyingWholesaleOrderList (data = {}) {
  return request({
    url: '/api/order/detailed/getGroupBuyingWholesaleOrderList',
    method: 'POST',
    data: data,
  })
}



// 商品查询团购订单
export function getGroupBuyingItem (data) {
  if (!data?.keyword) {
    data.keyword = ''
  }
  return request({
    url: '/api/order/detailed/getGroupBuyingItem',
    method: 'GET',
    params: data,
  })
}


// 商品查询团购订单详情列表
export function getGroupBuyingByItemId (data) {
  if (!data?.keyword) {
    data.keyword = ''
  }
  return request({
    url: '/api/order/detailed/getGroupBuyingByItemId',
    method: 'GET',
    params: data,
  })
}

//通过审核
export function groupBuyingSuccess (data = {}) {
  return request({
    url: '/api/order/detailed/groupBuyingSuccess',
    method: 'POST',
    data: data,
  })
}



export function fetchGoods (orderId) {
  return request({
    url: '/api/order/detailed/getOrderDetailed',
    method: 'GET',
    params: { orderId }
  })
}

// 确认退货，换货
export function confirmReturn (afterSalesId) {
  return request({
    url: '/api/order/afterSales/confirmReturn',
    method: 'GET',
    params: { afterSalesId }
  })
}

// 拒绝售后
export function refuse (afterSalesId) {
  return request({
    url: '/api/order/afterSales/refuse',
    method: 'GET',
    params: { afterSalesId }
  })
}

// 确认退款
export function confirmRefund (afterSalesId) {
  return request({
    url: '/api/order/afterSales/confirmRefund',
    method: 'GET',
    params: { afterSalesId }
  })
}



export default {
  orderGoodsList,
  fetchGoods,
  confirmReturn,
  refuse,
  confirmRefund,
  getGroupBuyingWholesaleOrderList,
  getGroupBuyingItem,
  getGroupBuyingByItemId
}