<!--
 * @Descripttion: 售后订单
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-25 10:39:55
 * @LastEditors: pj
 * @LastEditTime: 2023-05-11 17:35:34
-->
<template>
  <div class="orderInfo">
    <div class="title">退款信息</div>
    <el-form :model="info" ref="form" size="mini">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="orderId" label="订单编号">
            <div>{{ info.orderId }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="createTime" label="下单时间">
            <div>{{ info.createTime }}</div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="payType" label="支付方式">
            <div>{{ payTypeInfo[info.payType] }}</div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="buyerName" label="昵称">
            <div>{{ info.buyerName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="receivePhone" label="联系电话">
            <div>{{ info.receivePhone }}</div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="receiveFullAddress" label="收货地址">
            <div>{{ info.receiveFullAddress }}</div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <!-- <el-table-column prop="orderId" label="订单ID" width="100"></el-table-column> -->
      <el-table-column prop="cover" label="商品">
        <template slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.skuPicture"
            fit="cover"
            :preview-src-list="[scope.row.skuPicture]"
          />
        </template>
      </el-table-column>
      <el-table-column prop="itemName" label="商品名称"></el-table-column>
      <el-table-column prop="skuName" label="商品规格"></el-table-column>
      <el-table-column prop="skuSize" label="商品尺寸"></el-table-column>
      <!-- <el-table-column prop="orderStatusText" label="状态"></el-table-column> -->
      <el-table-column prop="itemNum" label="数量"></el-table-column>
      <!-- <el-table-column prop="totalDiscount" label="优惠"></el-table-column> -->
      <!-- <el-table-column prop="totalPrice" label="商品总价(元)"></el-table-column> -->
      <el-table-column prop="freight" label="运费(元)"></el-table-column>
      <el-table-column prop="payPrice" label="实付金额(元)"></el-table-column>
    </el-table>
    <div class="total-box">
      <span
        >实际收款： <span class="price">{{ info.payPrice }}</span> 元</span
      >
    </div>

    <div class="title">申请记录</div>
    <el-timeline v-for="(item, index) in info.afterSalesList" :key="index">
      <el-timeline-item
        :timestamp="item.orderAfterSalesVO.applyTime"
        placement="top"
      >
        <el-card>
          <div class="orderInfo-applyList">
            <div class="orderInfo-applyList-child">
              售后ID：{{ item.orderAfterSalesVO.afterSalesId }}
            </div>
            <div class="orderInfo-applyList-child">
              申请时间：{{ item.orderAfterSalesVO.applyTime }}
            </div>
            <div class="orderInfo-applyList-child">
              状态：{{ stateInfo[item.orderAfterSalesVO.state] }}
            </div>

            <div class="orderInfo-applyList-child">
              物流状态：{{
                logisticsStatusInfo[item.orderAfterSalesVO.logisticsStatus]
              }}
            </div>
            <div class="orderInfo-applyList-child">
              扣除发货运费：{{ item.orderAfterSalesVO.deliverFreight }}
            </div>

            <div class="orderInfo-applyList-child">
              退款总金额：{{ item.orderAfterSalesVO.refundTotalPrice }}
            </div>
            <div class="orderInfo-applyList-child">
              快递单号：{{ item.orderAfterSalesVO.courierNumber }}
            </div>

            <div class="orderInfo-applyList-child">
              退换类型：{{
                afterSalesTypeInfo[item.orderAfterSalesVO.afterSalesType]
              }}
            </div>
            <div class="orderInfo-applyList-child">
              退换货原因：{{ reasonInfo[item.orderAfterSalesVO.reason] }}
            </div>
            <div class="orderInfo-applyList-child">
              问题描述：{{ item.orderAfterSalesVO.remark }}
            </div>
          </div>
          <div>
            图片凭证：
            <div
              v-for="(item2, index2) in item.orderAfterSalesPictureVOList"
              :key="index2"
            >
              <img :src="item2.pictureUrl" alt="" />
            </div>
          </div>
          <div class="orderInfo-applyList-button">
            <after-sales-button
              :state="item.orderAfterSalesVO.state"
              :afterSalesType="item.orderAfterSalesVO.afterSalesType"
              @confirm="
                handleConfirm($event, item.orderAfterSalesVO.afterSalesId)
              "
              @reject="handleReject(item.orderAfterSalesVO.afterSalesId)"
            ></after-sales-button>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import AfterSalesButton from '@/components/AfterSalesButton.vue'
import { confirmReturn, refuse, confirmRefund } from '@/api/coox/orderGoods.js'

export default {
  name: 'orderInfo',
  components: {
    AfterSalesButton,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    tableData() {
      console.log(this.info, 'info')
      const item = {
        skuPicture: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
        name: 'evan',
        ...this.info,
      }
      return [item]
    },
    //支付类型
    payTypeInfo() {
      return {
        1: '微信',
        2: '支付宝',
        3: '余额',
        4: '线下支付',
      }
    },
    // 物流状态
    logisticsStatusInfo() {
      return {
        1: '未发货',
        2: '未收货',
        3: '已收货',
      }
    },
    // 退换类型
    afterSalesTypeInfo() {
      return {
        1: '退货退款',
        2: '换货',
        3: '仅退款',
      }
    },
    // 原因
    reasonInfo() {
      return {
        1: '这类IP不喜欢',
        2: '暂时不想要了',
        3: '商品选择错误',
        4: '质量问题',
      }
    },
    // 状态
    stateInfo() {
      return {
        1: '待审核',
        2: '等待买家退货',
        3: '待收货',
        4: '换货中',
        5: '退款中',
        6: '已完成',
        7: '已关闭',
        8: '已拒绝',
        9: '待发货',
      }
    },
  },
  data() {
    return {}
  },
  methods: {
    handleConfirm(action, afterSalesId) {
      const parmas = afterSalesId
      console.log(parmas, 'parmas')
      switch (action) {
        case 'return':
          console.log('Confirmed: return')
          // 在这里处理确认退货操作，例如发送请求等
          this.$confirm('确认退货吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(async () => {
              await confirmReturn(parmas)
              this.$message({
                type: 'success',
                message: '提交成功!',
              })
              this.$emit('confirm', 'Reload')
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
          break
        case 'exchange':
          // 在这里处理确认换货操作，例如发送请求等
          console.log('Confirmed: exchange')
          this.$confirm('确认换货吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(async () => {
              await confirmReturn(parmas)
              this.$message({
                type: 'success',
                message: '提交成功!',
              })
              this.$emit('confirm', 'Reload')
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
          break
        case 'refund':
          console.log('Confirmed: refund')
          // 在这里处理确认退款操作，例如发送请求等
          this.$confirm('确认退款后订单款将退回到买家账户', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(async () => {
              await confirmRefund(parmas)
              this.$message({
                type: 'success',
                message: '提交成功!',
              })
              this.$emit('confirm', 'Reload')
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
          break
        default:
          console.error('Invalid action')
      }
    },
    handleReject(target) {
      console.log('Rejected: after-sales')
      this.$confirm('是否拒绝售后？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await refuse(target)
          this.$message({
            type: 'success',
            message: '提交成功!',
          })
          this.$emit('confirm', 'Reload')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
      // 在这里处理拒绝售后操作，例如发送请求等
    },
  },
}
</script>

<style lang="scss" scoped>
.orderInfo {
  padding: 0 20px;
  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .total-box {
    border-bottom: 1px solid #ebeef5;
    height: 50px;
    line-height: 50px;
    text-align: right;
    padding-right: 10px;
    margin-bottom: 30px;
    .price {
      font-size: 16px;
      font-weight: bold;
      color: #f50;
      padding-right: 5px;
    }
  }
  &-applyList {
    display: flex;
    flex-wrap: wrap;
    &-child {
      margin-right: 100px;
      margin-bottom: 30px;
    }
    &-button {
      margin-top: 30px;
    }
  }
}
</style>

<style lang="scss">
.orderInfo {
  .el-form .el-form-item {
    margin-bottom: 3px !important;
  }
  .el-table__header th {
    border-bottom: 1px solid #ddd !important;
    // background-color: #EEF2FE !important;
    background-color: #e8f2ff !important;
  }
}
</style>
