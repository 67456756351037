<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-04-25 14:57:16
 * @LastEditors: pj
 * @LastEditTime: 2023-04-26 15:02:31
-->
<template>
  <div>
    <el-button type="primary" v-if="showButton" @click="handleClick">
      {{ buttonText }}
    </el-button>

    <el-button type="danger" @click="handleReject" v-if="showButton2">
      拒绝售后
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
      required: true,
    },
    afterSalesType: {
      type: Number,
      required: true,
    },
  },
  computed: {
    showButton() {
      return this.state === 1 || this.state === 2 || this.state === 3
    },
    showButton2() {
      return this.state !== 7 && this.state !== 8
    },
    buttonText() {
      if (this.state === 1) {
        switch (this.afterSalesType) {
          case 1:
            return '确认退货'
          case 2:
            return '确认换货'
          case 3:
            return '确认退款'
          default:
            return ''
        }
      } else if (this.state === 2 || this.state === 3) {
        return '确认退款'
      }
      return ''
    },
  },
  methods: {
    handleClick() {
      switch (this.buttonText) {
        case '确认退货':
          this.confirmReturn()
          break
        case '确认换货':
          this.confirmExchange()
          break
        case '确认退款':
          this.confirmRefund()
          break
        default:
          console.error('Invalid action')
      }
    },
    confirmReturn() {
      this.$emit('confirm', 'return')
      // 在这里处理确认退货操作，例如发送请求等
    },
    confirmExchange() {
      this.$emit('confirm', 'exchange')
      // 在这里处理确认换货操作，例如发送请求等
    },
    confirmRefund() {
      this.$emit('confirm', 'refund')
      // 在这里处理确认退款操作，例如发送请求等
    },
    handleReject() {
      this.$emit('reject')
      // 在这里处理拒绝售后操作，例如发送请求等
    },
  },
}
</script>
